
import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>APICECTOMY</h2>
      <h3>What is it?</h3>
      <p>Teeth are held in the jaw by their roots.</p>
      <p>At the core of each tooth is a soft mass of tissue called the dental pulp. In a healthy tooth,the pulp contains
        living fibres, cells, nerves and a blood supply, extending into the root(s) through the root canal(s). Tooth
        decay
        or injury can destroy the living pulp. Dead pulp is more prone to infection, leading to an abscess and
        toothache.
        This can usually be dealt with successfully by root canal treatment (also known as root filling or endodontics).
        Sometimes infection can persist at the end of a tooth root, in thesurrounding bone. In this case, a dentist can
        carry out an apicectomy.</p>
      <h3>What will my dentist do?</h3>
      <p>They will:</p>
      <ul>
        <li>give you a local anaesthetic to numb the mouth around the infected tooth, so that the procedure is painless
          make
          a small cut in the gum, well away from the tooth, so that there won be a visible scar afterwards
        </li>
        <li>move a small flap of gum to one side, and make a small window in the bone, to uncover the infected area and
          clean out the infection and cut off the tip, or apex, of the root
        </li>
        <li>put a small filling at the end of the root canal to stop any more infection</li>
        <li>stitch the gum back in place. .You may feel some pressure and hear instruments being used, you should not
          feel
          any pain during an apicectomy.
        </li>
      </ul>
      <p>After treatment you will need to keep the area clean.</p>
      <p>For the first day, rinse with warm salt water several times a day, especially after meals.</p>
      <p>Use half a teaspoon of salt in a glass of warm water, not hotter than is comfortable.</p>
      <p>Brush the teeth normally, but be very careful not to disturb the cut.</p>
      <p>On the next day, continue rinsing and begin to gently brush the teeth next to the cut. there may be some
        bruising
        and swelling for two or three days afterwards. There will also be some slight discomfort- an ice-pack or a
        packet of
        frozen peas wrapped in a towel applied to the outside of the face can help for the first 24 hours. It usually
        takes
        about a week for an apicectomy to heal.</p>
      <p>Points to remember:</p>
      <p>An apicectomy stops pain and can save a tooth that would otherwise have to be taken out.</p>
    </LightboxLayout>
  )
}

export default Page